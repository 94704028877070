<template>
  <div class="unit">
    <el-card>
      <div class="pageBody">
        <div class="handleBoxWrap">
          <el-button size="small" type="primary" @click="addHandle()">新增单位</el-button>
        </div>
        <el-table v-loading="dataListLoading" :data="dataList" border :height="tabHeight">
          <el-table-column type="index" label="序号" align="center" header-align="center" width="66" :index="indexMethod"></el-table-column>
          <el-table-column v-for="row in headerList" :key="row.key" :prop="row.key" :min-width="row.width" :label="row.label" :show-overflow-tooltip="row.showOverflowTips" :formatter="row.formatter" header-align="center" align="center"></el-table-column>
          <el-table-column label="是否允许小数" align="center" header-align="center">
            <template slot-scope="scope">
              <template>
                <div>{{IsAllowDecimalId(scope.row.allowDecimalId)}}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="160">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editHandle(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="delHandle(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="curPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
        </el-pagination>
      </div>
    </el-card>
    <edit-dialog ref="editDialog" @reload="getDataList"></edit-dialog>
  </div>
</template>
<script>
import editDialog from './unitAdd.vue'

function getClientHeight () {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

export default {
  name: 'unit',
  components: { editDialog },
  data () {
    return {
      dataListLoading: false,
      dataList: [],
      curPage: 1,
      total: 0,
      limit: 20,
      headerList: [{
        key: 'name',
        label: '单位名称',
        width: 180
      }],
      dataForm: {
        keyWord: ''
      },
      tabHeight: '400',
      levelList: [],
      sourceList: [],
      unitAllowDecimalMap: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      // console.log(this.$utils.clientHeight() - 260 + 'px')
      this.tabHeight = getClientHeight() - 260 + 'px'
    })
  },
  activated () {
    this.getUnitAllowDecimalMap()
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http.get('/unit', {
        params: {
          page: this.curPage,
          limit: this.limit,
          ...this.dataForm
        }
      }).then(({ data: res }) => {
        let { code, msg, data } = res
        console.log(code, msg, data)
        if (code === 0 && data) {
          let { records, current, total } = data
          this.curPage = current * 1
          this.total = total * 1
          this.dataList = records && records.length ? records : []
        }
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.dataListLoading = false
      })
    },
    // 切换每页数量
    pageSizeChangeHandle (size) {
      this.limit = size
      this.curPage = 1
      this.getDataList()
    },
    // 切换页
    pageCurrentChangeHandle (curPage) {
      this.curPage = curPage
      this.getDataList()
    },
    resetSearch () {
      let form = this.$options.data().dataForm
      this.$set(this, 'dataForm', form)
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    addHandle () {
      this.$refs.editDialog.init()
    },
    // 修改
    editHandle (row) {
      console.log(row)
      this.$refs.editDialog.init(row)
    },
    // 删除
    delHandle (id) {
      // console.log(arguments)
      this.$confirm('确定删除该数据吗？', '删除确认').then(() => {
        return this.$http.delete(`/unit/${id}`, {})
      }).then(({ data: res }) => {
        let { code, msg } = res
        console.log(code, msg)
        if (code !== 0) {
          this.$message.error(msg)
        }
        this.getDataList()
      })
    },
    // 获取单位是否允许小数Map
    getUnitAllowDecimalMap () {
      this.$http.get('/unit/unitAllowDecimal').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          this.unitAllowDecimalMap = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    indexMethod (index) {
      return (this.curPage - 1) * this.limit + (index + 1)
    }
  },
  computed: {
    IsAllowDecimalId () {
      return function (type) {
        // Object.keys(this.unitAllowDecimalMap).forEach((key) => {
        //   console.log(key, this.unitAllowDecimalMap[key])
        // })
        return type === '1' ? '是' : '否'
      }
    }
  }
}
</script>
<style lang="scss">
  .unit {
    padding: 12px 20px 0;
    .handleBoxWrap {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;

      .el-select,.el-range-editor.el-input__inner,.el-input{
        margin: 0;
        margin-right: 10px;
        max-width: 200px;
      }
    }
    .el-pagination {
      text-align: right;
      margin-top: 6px;
    }
  }
</style>
